import { CTALabel } from "./cta.js"

// Notez que toutes les clés sont maintenant en français,
// mais pour la continuité sur Matomo nous gardons les valeurs anglaises
export enum EventCategory {
  Defaut = "defaultCategory",
  General = "General",
  Parcours = "Parcours",
  Resultats = "Résultats",
  Accompagnement = "Accompaniment",
  MontantAttendu = "Montant attendu",
  Followup = "Followup",
  Contact = "Contact",
  Tracer = "Tracer",
  Axe = "Axe",
  PNDS = "PNDS",
  Home = "Home",
  Partenaire = "Partenaire",
  Redirection = "Redirection",
}

export enum EventAction {
  AccesSimulationAnonymisee = "Accès simulation anonymisée",
  AfficheLienAccompagnement = "show-accompaniment-link",
  ShowPlansToAsk = "show-plans-to-ask",
  BoutonPrecedent = "Bouton précédent",
  CalculResultatsRestauration = "compute",
  ClickLienAccompagnement = "click-accompaniment-link",
  Close = "close",
  CopyLinkBenefit = "copy-link-benefit",
  CopyTemporarySimulationRecapUrlToClipboard = "copy-temporary-simulation-recap-url-to-clipboard",
  CodePostalIntrouvable = "Depcom introuvable",
  Contact = "Contact",
  Email = "email",
  ErreurInitStore = "Error",
  ErreurSauvegardeSimulation = "Erreur sauvegarde simulation",
  Form = CTALabel.Form,
  FormulaireAffiche = "Formulaire affiché",
  FormulaireEmailInvalide = "Invalid email form",
  FormulaireTelephoneInvalide = "Invalid phone form",
  FormulaireValideAvecRecontact = "Formulaire validé avec recontact",
  FormulaireValideSansRecontact = "Formulaire validé sans recontact",
  Instructions = CTALabel.Instructions,
  JeDonneMonAvis = "Je donne mon avis",
  Link = "link",
  LinkIneligible = "link-ineligible",
  Msa = "msa",
  NavigationAnnulee = "Navigation cancelled",
  Parcours = "Parcours",
  PlansToAskQuestion = "plans-to-ask-question",
  Redirection = "Redirection",
  ReprendreMaSimulation = "Reprendre ma simulation",
  RetourLogement = "retour-logement",
  SauvegardeDonnees = "Sauvegarde des données",
  TemporarySimulationSave = "temporary-simulation-save",
  ShareLinkByEmail = "share-link-email",
  Show = "show",
  ShowDetails = "showDetails",
  ShowDetailsFromGroupPage = "showDetailsFromGroupPage",
  ShowLocations = "show-locations",
  ShowNewLocation = "show-new-location",
  ShowUnexpected = "show-unexpected",
  ShowUnexpectedAmountLink = "show-unexpected-amount-link",
  SimulationCaf = "simulation-caf",
  Teleservice = CTALabel.Teleservice,
  SiteInternet = "Site internet",
  Support = "Support",
  Telephone = "Téléphone",
  TelechargementDonnees = "Téléchargement données",
}
